<template>
	<div id="productMenu">
		<div
			style="font-size: 20px; font-weight: bold; width: 100%; background-color: #163E87; color: #fff; line-height: 50px;">
			{{ $t("product_center") }}
		</div>
		<el-menu :default-active="defaultActive" :default-openeds="openeds" class="el-menu-vertical" mode="vertical" @open="handleTopTypeOpen">
			<el-submenu :index="idx + ''" v-for="(item, idx) in types" :key="item.id" align="left">
				<template slot="title">
					<i class="el-icon-menu"></i>
					<span slot="title" :class="selectedTypeIndex == idx ? 'selectedTitle' : ''">{{ cur_lang == "zh" ? item.nameCh : item.nameEn }}</span>
				</template>

				<el-menu-item :index="idx + '-' + sub_idx" v-for="(sub_item, sub_idx) in item.column" :key="sub_item.id"
					align="left" @click.native="handleSubTypeSelect(sub_item, idx, sub_idx)">
					<template slot="title">
						<i class="el-icon-s-grid"></i>
						<span slot="title">{{ cur_lang == "zh" ? sub_item.nameCh : sub_item.nameEn }}</span>
					</template>
				</el-menu-item>
			</el-submenu>
		</el-menu>
	</div>

</template>

<script>
	export default {
		name: "ProductMenu",
		// 1: 一级大类介绍页；2: 二级分类（产品）介绍页；3: 型号列表页、型号信息页
		props:["pageStatus"],
		data() {
			return {
				// 产品类型
				selectedTypeIndex: -1,
				types: [],
				left_index: 0,
				left_sub_index: 0,
				defaultActive: "0",
				cur_lang: "zh",
				openeds: [],
			};
		},

		created() {
			let cur_lang = localStorage.getItem("locale");
			if (cur_lang) {
				this.cur_lang = cur_lang
			}
			
			// 获取类型信息
			this.getProductTypes();
			
			// 用来显示产品
			// console.log('Product center....')
			
			// 获取链接从?号开始的参数,如:  ?a=1&b=2
			let url = location.search;
			if (url.indexOf("?") != -1) {
				// 去除问号
				let str = url.substr(1);
				// 分离参数
				let paramersArray = str.split("&");
				// console.log(url, str, strs)
				
				let haveTypeIndex = false;
				let haveTypeSubIndex = false;
				for (var i = 0; i < paramersArray.length; i++) {
					let paramer = paramersArray[i];
					if (paramer.indexOf('type_index') != -1) {
						haveTypeIndex = true;
						// 解码参数
						this.left_index = decodeURIComponent(paramer.replace("type_index=", ""));
					} else if (paramer.indexOf('type_sub_index') != -1){
						haveTypeSubIndex = true;
						this.left_sub_index = decodeURIComponent(paramer.replace("type_sub_index=", ""));
					} 
				}
				
				if (haveTypeIndex && haveTypeSubIndex) {
					// 用户选中了二级分类
					this.defaultActive = this.left_index + "-" + this.left_sub_index;
				} else if (haveTypeIndex == true && haveTypeSubIndex == false) {
					// 点击大类标题
					this.selectedTypeIndex = this.left_index;
					// 展开子菜单的index
					this.openeds = [this.left_index];
				}
			} 
			
		},

		mounted() {
			const that = this;
			window.addEventListener("setItem", function(e) {
				// console.log('监听到了~', e.newValue, e.oldValue);
				if (e.key == 'locale') {
					// 监听切换中英
					that.cur_lang = e.newValue;
				} else if (e.key == 'types'){
					console.log('product menu 监听到完成类型获取')
					// 1、复制链接时，无类型缓存，common header 会进行请求, 监听请求完成即可
					that.getProductTypes();
				}
			});
		},

		methods: {

			getProductTypes: function() {
				let types_json = sessionStorage.getItem('types');
				if (types_json == null) return;
				this.types = JSON.parse(types_json);
			},
			
			// 处理点击一级大类
			handleTopTypeOpen: function(key, keyPath) {
				// console.log("product menu type 展开一级: ", this.types[key], keyPath);
				
				// 显示大类介绍
				// 当前页点击，为减少切换页面时的闪白屏
				if (this.$props.pageStatus == 1) {
					this.selectedTypeIndex = key;
					this.$addStorageEvent(2, 'is_update_top_type', key);
				} else {
					// 其他页点击
					window.location.href = 'product_type.html?type_index=' + key;
				}
				
			},

			// 处理二级类型选择
			handleSubTypeSelect: function(sub_item, index, sub_index) {
				if (this.$props.pageStatus == 2) {
					// 当前页点击，为减少切换页面时的闪白屏
					this.defaultActive = index + "-" + sub_index;
					this.$addStorageEvent(2, 'is_update_second_type', this.defaultActive);
				} else {
					window.location.href = 'product.html?type_index=' + index + '&type_sub_index=' + sub_index;
				}
				
			}
		},
	}
</script>

<style>
	#productMenu {
		font-size: 20px;
		font-weight: bold;
		width: 100%;
		background-color: #163E87;
		color: #fff;
		line-height: 50px;
	}

	/* 设置菜单栏文字过长时自动换行 */
	.el-submenu__title {
		display: flex;
		align-items: center;
		text-align: left;
	}

	.el-submenu__title span {
		white-space: normal;
		/* 按单词换行 */
		word-break: break-word;
		line-height: 25px;
		flex: 1;
		padding-right: 5px;
		text-align: left;
	}

	.el-menu-item {
		display: flex;
		align-items: center;
		padding-right: 20px !important;
		text-align: left;
	}

	.el-menu-item span {
		white-space: normal;
		/* 按单词换行 */
		word-break: break-word;
		line-height: 25px;
		flex: 1;
		padding-right: 5px;
		text-align: left;
	}
	
	.selectedTitle {
		color: rgb(50, 135, 255);
	}
</style>
