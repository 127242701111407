import Axios from "axios";
/** 扁平数据 json格式化
 * @param {Object} data
 */
function formatJson(data) {
  let result = [],
    temp = {},
    i = 0,
    j = 0,
    len = data.length;
  for (; i < len; i++) {
    // 以id作为索引存储元素，可以无需遍历直接定位元素
    temp[data[i]["id"]] = data[i];
  }
  for (; j < len; j++) {
    let currentElement = data[j];
    // 临时变量里面的当前元素的父元素 parentId 父级ID
    let tempCurrentElementParent = temp[currentElement["parent_id"]];
    // 如果存在父元素
    if (tempCurrentElementParent) {
      // 如果父元素没有chindren键
      if (!tempCurrentElementParent["children"]) {
        // 设上父元素的children键
        tempCurrentElementParent["children"] = [];
      }
      // 给父元素加上当前元素作为子元素
      tempCurrentElementParent["children"].push(currentElement);
    } else {
      // 不存在父元素，意味着当前元素是一级元素
      result.push(currentElement);
    }
  }
  return result;
}

/**16进制显示base64
 * @param {Object} base64
 */
function base64ToHex16(base64) {
  return window
    .atob(base64)
    .split("")
    .map(function(aChar) {
      return ("0" + aChar.charCodeAt(0).toString(16)).slice(-2);
    })
    .join("")
    .toUpperCase();
}

/** 获取指定格式的时间字符串
 * @param {Object} formatStr
 * @return {timeStr} 格式为 年月日 HH:mm:ss 或 yyyy-MM-dd HH:mm:ss 或 yyyy/MM/dd HH:mm:ss
 */
function timeToFormatString(date, formatStr) {
  if (date == null) {
    date = new Date();
  }
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();

  if (month < 10) month = "0" + month;
  if (day < 10) day = "0" + day;
  if (hours < 10) hours = "0" + hours;
  if (minutes < 10) minutes = "0" + minutes;
  if (seconds < 10) seconds = "0" + seconds;

  let currentDateStr;

  if (formatStr.indexOf("-") > 0) {
    // yyyy-MM-dd HH:mm:ss
    currentDateStr =
      year +
      "-" +
      month +
      "-" +
      day +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds;
  } else if (formatStr.indexOf("/") > 0) {
    // yyyy/MM/dd HH:mm:ss
    currentDateStr =
      year +
      "/" +
      month +
      "/" +
      day +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds;
  } else {
    // 年月日
    currentDateStr =
      year +
      "年" +
      month +
      "月" +
      day +
      "日" +
      "	" +
      hours +
      ":" +
      minutes +
      ":" +
      seconds;
  }
  return currentDateStr;
}

/**
 * http post网络请求
 * @param  url 请求地址
 * @param  data 请求参数
 * @param  successCallback 回调
 * @param  failCallback 回调
 */
function httpRequestPost(url, data, successCallback, failCallback) {
  this.httpRequest("post", url, data, successCallback, failCallback);
}

/**
 * http get网络请求
 * @param  url 请求地址
 * @param  data 请求参数
 * @param  successCallback 回调
 * @param  failCallback 回调
 */
function httpRequestGet(url, data, successCallback, failCallback) {
  this.httpRequest("get", url, data, successCallback, failCallback);
}

/**
 * http del网络请求
 * @param  url 请求地址
 * @param  data 请求参数
 * @param  successCallback 回调
 * @param  failCallback 回调
 */
function httpRequestDelete(url, data, successCallback, failCallback) {
  this.httpRequest("delete", url, data, successCallback, failCallback);
}

/**
 * http patch网络请求
 * @param  url 请求地址
 * @param  data 请求参数
 * @param  successCallback 回调
 * @param  failCallback 回调
 */
function httpRequestPatch(url, data, successCallback, failCallback) {
  this.httpRequest("patch", url, data, successCallback, failCallback);
}

/**
 * http网络请求
 * @param  method 请求方式
 * @param  url 请求地址
 * @param  data 请求参数
 * @param  successCallback 回调
 * @param  failCallback 回调
 */
function httpRequest(method, url, data, successCallback, failCallback) {
  Axios({
    method: method,
    url: url,
    data: data
    // headers: {
    //   Authorization: localStorage.getItem('author')
    // },
  })
    .then(function(response) {
      let code = response.data.code;
      let result = response.data;
      if (code == 200) {
        // 成功回调
        successCallback(result);
      } else {
        if (failCallback == null) {
          console.log(url, "获取失败: ", result);
        } else {
          failCallback(result);
        }
      }
    })
    .catch(function(error) {
      console.log(url, "请求失败", error);
      // alert(error);
    });
}

/**
 * 是否是同一天
 * @param {Object} startTime 开始时间
 * @param {Object} endTime 结束时间
 * @return {Bool} 结果返回
 */
function isSameDay(startTime, endTime) {
  // setHours(hour, min, sec, millsec) 将时分秒毫秒置为0
  const startTimeMs = new Date(startTime).setHours(0, 0, 0, 0);
  const endTimeMs = new Date(endTime).setHours(0, 0, 0, 0);
  return startTimeMs === endTimeMs ? true : false;
}

/**
 * 使用indexof方法实现模糊查询
 * @param  {Array}  list     进行查询的数组
 * @param  {String} keyWord  查询的关键词
 * @return {Array}           查询的结果
 */
function fuzzyQuery(list, keyWord) {
  var arr = [];
  for (var i = 0; i < list.length; i++) {
    let data = list[i];

    let jsonStr = JSON.stringify(data);

    if (jsonStr.indexOf(keyWord) >= 0) {
      arr.push(data);
    }
  }
  return arr;
}
// 判断字符串是否为空
function stringIsEmpty(property) {
  return (
    property === null || property === "" || typeof property === "undefined"
  );
}



// 将方法暴露出去
export {
  formatJson,
  base64ToHex16,
  timeToFormatString,
  httpRequestPost,
  httpRequestGet,
  httpRequestDelete,
  httpRequestPatch,
  httpRequest,
  isSameDay,
  fuzzyQuery,
  stringIsEmpty,
};
